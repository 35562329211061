import { Container, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import './navbar.css';
import { Link } from 'react-router-dom';

function KailiNavbar({ logo }) {
  return (
    <Navbar expand="lg" className='bg-white py-3'>
      <Container>
        {/* Logo */}
        <Navbar.Brand>
          <img src={logo} style={{ height: '10vmin' }} alt="logo" /> {/* Ajusta el tamaño del logo */}
        </Navbar.Brand>

        {/* Toggle para menú colapsable en móviles */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Menú de navegación */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='mx-auto'> {/* Centrado del menú */}
            <Nav.Link as={Link} to='/' className='fs-5 mx-3 nav-buttons'>
              Inicio
            </Nav.Link>
            <Nav.Link as={Link} to='/about' className='fs-5 mx-3 nav-buttons'>
              ¿Quiénes somos?
            </Nav.Link>
            <Nav.Link as={Link} to='/que-hacemos' className='fs-5 mx-3 nav-buttons'>
              ¿Qué hacemos?
            </Nav.Link>
            <Nav.Link as={Link} to='/contacto' className='fs-5 mx-3 nav-buttons'>
              Contacto
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {/* Botón de Reservar */}
        <Form className="d-flex">
          <Button className='custom-button btn-md'>
            <a href="https://wa.me/56957692636?text=Hola,%20me%20gustaría%20agendar%20una%20hora%20con%20ustedes." target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              AGENDA TU HORA
            </a>
          </Button>
        </Form>
      </Container>
    </Navbar>
  );
}

export default KailiNavbar;
