import React from 'react';
import './home.css'; // Asegúrate de importar tus estilos CSS
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col, Button } from 'react-bootstrap';
import imagenInstagram1 from '../../images/imagen-1.png';
import imagenInstagram2 from '../../images/imagen-2.png';
import imagenInstagram3 from '../../images/imagen-3.png';
import imagenFono from '../../images/fono-imagen-home.jpeg';
import imagenTOAdult from '../../images/imagen-to-adulto.jpg';
import imagenSpeechAdult from '../../images/senior-speech-therapy.jpg';
import imagenPerfilSensorial from '../../images/perfil-sensorial-5.jpg';
import imagenPsicologia from '../../images/imagen-psicologia-2.jpg';
import imagenAsesoria from '../../images/imagen-asesoria.jpg';
import imagenVoz from '../../images/voz-2.webp';
import imagenHero6 from '../../images/imagen-hero-6.jpg';
import imagenHero7 from '../../images/imagen-hero-7.jpg';
import imagenHero8 from '../../images/imagen-hero-8.jpg';
import imagenHero2 from '../../images/niñas-7.jpeg';
import proffesional1 from '../../images/profesionales-1-nobg.png';
import proffesional2 from '../../images/profesionales-4-nobg.png';
import proffesional3 from '../../images/profesionales-5-nobg.png';
import proffesional4 from '../../images/profesionales-3-nobg.png';
import proffesional5 from '../../images/profesionales-2-nobg.png';
import proffesional6 from '../../images/fono-voz-2.png';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FaHouseUser } from 'react-icons/fa';
import { FaHouseSignal } from 'react-icons/fa6';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagenTO from '../../images/occuptaional-kid-session.jpg'
import { Fade } from 'react-awesome-reveal';

function Home() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };


    return (


        <>
            <Fade>
                <Row>
                    <div className="hero-image">
                        <Slider {...settings}>
                            <div>
                                <img src={imagenHero6} alt="Imagen 2" className="hero-slider-image" />
                            </div>
                            <div>
                                <img src={imagenHero7} alt="Imagen 2" className="hero-slider-image" />
                            </div>
                            <div>
                                <img src={imagenHero8} alt="Imagen 2" className="hero-slider-image" />
                            </div>
                            <div>
                                <img src={imagenHero2} alt="Imagen 2" className="hero-slider-image" />
                            </div>
                        </Slider>
                        <div className="hero-overlay"></div>
                        <div className="hero-text">
                            <h1 className='display-4 fw-bold text-sm-start text-md-start text-lg-start text-xl-start'>¡Bienvenidos a Centro Kaili!</h1>
                            <p className='fs-2 fw-bold fs-md-4 fs-lg-3'>Centro de atención a domicilio y online</p>
                            <p className='fs-4 fs-md-4 fs-lg-3'>Atención a bebés, niños, adolescentes, adultos y personas mayores</p>
                            <p className='fs-4 fs-md-4 fs-lg-3'>¿Tienes dudas o preguntas?</p>
                            <Button className='custom-button btn-md'>
                                <a href="https://wa.me/56957692636?text=Hola,%20me%20interesa%20más%20información" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    CONSULTA AQUÍ
                                </a>
                            </Button>
                        </div>
                    </div>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-4 fw-bold' style={{ color: '#219ebc' }}>Nuestros servicios</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Container className='mt-5'>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenTO} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-7 text-center'><strong>Terapia Ocupacional</strong> para niños y adolescentes</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20para%20terapia%20ocupacional%20infanto-juvenil%20a%20domicilio/online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenFono} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-7 text-center'><strong>Fonoaudiología</strong> para niños y adolescentes</Card.Title>
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20para%20fonoaudiología%20infanto-juvenil%20a%20domicilio/online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenTOAdult} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-7 text-center'><strong>Terapia Ocupacional</strong> para adultos y personas mayores</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20de%20terapia%20ocupacional%20para%20adulto-persona%20mayor%20a%20domicilio/online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenSpeechAdult} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-7 text-center'><strong>Fonoaudiología</strong> para adultos y personas mayores</Card.Title>
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20de%20fonoaudiología%20para%20adulto-persona%20mayor%20a%20domicilio/online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%', borderRadius: '20px !important' }}>
                                <Card.Img variant="top" src={imagenPerfilSensorial} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#48cae4'}}>
                                    <Card.Title className='fs-7 text-center'>Evaluación del <strong>Perfil Sensorial</strong> en adolescentes y adultos</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20para%20perfil%20sensorial%20para%20adolescentes%20y%20adultos%20%20de%20forma%20online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenVoz} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-7 text-center'>Intervención de fonoaudiología en <strong>área de voz</strong> entregando herramientas y técnicas vocales</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20para%20intervenir%20en%20área%20de%20voz%20a%20domicilio/online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenPsicologia} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 text-center'><strong>Psicología</strong> para adolescentes y adultos</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20con%20psicología%20para%20adolescentes-adultos%20de%20forma%20online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex flex-column align-items-center'>
                            <Card className="h-100 card-custom" style={{ width: '60%' }}>
                                <Card.Img variant="top" src={imagenAsesoria} width="100%" height="auto" />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 text-center'><strong>Asesorías</strong> y orientación a padres y a cuidadores de personas con demencia</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                            <div className="">
                                <Button className='button-custom'>
                                    <a href="https://wa.me/56957692636?text=Hola,%20quiero%20agendar%20una%20hora%20para%20asesoria%20y%20orientacion%20de%20forma%20online" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        Agendar
                                    </a></Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-4 fw-bold' style={{ color: '#219ebc' }}>Modalidad de atención</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Container>
                    <Row className='mt-5 text-center'>
                        <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3'>
                            <div style={{ textAlign: 'center' }}>
                                <FaHouseUser size='8em' style={{ color: '#FFD700' }} />
                                <h3 className='fs-4 mt-4 fw-bold'>Atención a domicilio en algunas comunas de la Región Metropolitana</h3>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3'>
                            <div style={{ textAlign: 'center' }}>
                                <FaHouseSignal size='8em' style={{ color: '#A7E8AF' }} />
                                <h3 className='fs-4 mt-4 fw-bold'>Atención online a personas desde los 14 años en adelante</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-4 fw-bold' style={{ color: '#219ebc' }}>Nuestro equipo</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Container className='mt-5'>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="prof-card">
                                <Card.Img variant="top" src={proffesional1} width="100%" height="auto" className='card-professional' />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 fw-bold text-center'>Macarena Guede</Card.Title>
                                    <Card.Text className='text-center' style={{ color: '#2D2D2D !important', fontSize: '0.875rem' }}>
                                        Fundadora de Centro Kaili y Terapeuta Ocupacional dedicada a la geriatría y gerontología, especialmente en trastornos neurocognitivos.
                                        Cuenta con experiencia en el trabajo con personas mayores autovalentes, con deterioro cognitivo y con distintos tipos de demencia.
                                    </Card.Text>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="prof-card">
                                <Card.Img variant="top" src={proffesional2} width="100%" height="auto" className='card-professional' />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 fw-bold text-center'>Antonia Salinas</Card.Title>
                                    <Card.Text className='text-center' style={{ color: '#2D2D2D !important', fontSize: '0.875rem' }}>
                                        Terapeuta Ocupacional dedicada al área infanto-juvenil. Tiene experiencia con niños(as) y adolescentes, abordando diversos desafíos en alimentación,
                                        conducta, disfunción en el procesamiento sensorial y trastornos del neurodesarrollo (Condición del Espectro Autista, Trastorno por déficit de Atención con o sin hiperactividad (TDA-H)),
                                        Síndrome de Down y retrasos en el desarrollo psicomotor. Además, se dedica a la atención y estimulación temprana.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="prof-card">
                                <Card.Img variant="top" src={proffesional3} width="100%" height="auto" className='card-professional' />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 fw-bold text-center'>Camila Acevedo</Card.Title>
                                    <Card.Text className='text-center' style={{ fontSize: '0.875rem' }}>
                                        Fonoaudióloga dedicada al área infanto-juvenil. Camila tiene experiencia en atención temprana en desafíos de comunicación como el desarrollo del
                                        lenguaje, habla y comunicación. Ademas, aborda la alimentación complementaria y desafíos en alimentación como selectividad y rechazo alimentario.
                                    </Card.Text>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="prof-card">
                                <Card.Img variant="top" src={proffesional4} width="100%" height="auto" className='card-professional' />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 fw-bold text-center'>María Ignacia Carvallo</Card.Title>
                                    <Card.Text className='text-center' style={{ fontSize: '0.875rem' }}>
                                        Terapeuta Ocupacional dedicada a población adolescente, adulta y personas mayores. Tiene formación y experiencia en salud mental y
                                        psiquiatría, neurodivergencia y trastornos neurocognitivos. Se enfoca en brindar atención integral de Terapia Ocupacional
                                        especializada en cada usuario(a), sus necesidades, interes y contextos de desempeño, trabajando estrechamente con sus redes de apoyo
                                        principales.
                                        Realiza evaluación del Perfil Sensorial en adolescentes y adultos.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="prof-card">
                                <Card.Img variant="top" src={proffesional5} width="100%" height="auto" className='card-professional' />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 fw-bold text-center'>Stefany Arévalo</Card.Title>
                                    <Card.Text className='text-center' style={{ fontSize: '0.875rem' }}>
                                        Terapeuta Ocupacional dedicada al área infanto-juvenil. Tiene experiencia en atención clínica y en programas de integración escolar (PIE). Actualmente se encuentra realizando certificación en integración sensorial.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="prof-card">
                                <Card.Img variant="top" src={proffesional6} width="100%" height="auto" className='card-professional' />
                                <Card.Body className='card-body'>
                                    <Card.Title className='fs-5 fw-bold text-center'>Francisca Alarcón</Card.Title>
                                    <Card.Text className='text-center' style={{ fontSize: '0.875rem' }}>
                                        Fonoaudióloga dedicada al área de voz. Especializada en la evaluación, rehabilitación y entrenamiento de la voz hablada y cantada en población adulta e infanto juvenil. 
                                        Cuenta con experiencia en la rehabilitación de diversas patologías vocales, entrenamiento vocal a profesionales de la voz y en el acompañamiento fonoaudiológico en procesos 
                                        de búsqueda de identidad vocal en personas trans o con necesidades vocales diversas.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Row className='mt-5'>
                    <h2 className='text-center display-4 fw-bold' style={{ color: '#219ebc' }}>Nuestro contenido en Instagram</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5 justify-content-center'>
                    <Col md={3}>
                        <Card style={{ width: '80%' }} className='mx-auto'>
                            <a href='https://www.instagram.com/p/C3QGyIzuepp/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram1} />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card style={{ width: '80%' }} className='mx-auto'>
                            <a href='https://www.instagram.com/p/C3ctkQNuE-y/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram3} />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card style={{ width: '80%' }} className='mx-auto'>
                            <a href='https://www.instagram.com/p/C30kPqzpmUe/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram2} />
                            </a>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-5'></Row>

                <Row className='mt-3 mb-5 justify-content-center'>
                    <Col xs="auto">
                        <Button className='follow-button' size='lg' href="https://www.instagram.com/centro.kaili/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="mr-2" /> Síguenos en Instagram
                        </Button>
                    </Col>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>
            </Fade>
        </>
    );
}

export default Home;
