import { Row, Container, Col, Button, Form } from "react-bootstrap";
import './contact.css';
import emailjs from '@emailjs/browser';
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

emailjs.init({
    publicKey: process.env.REACT_APP_EMAILJS_API_KEY,
    blockHeadless: true,
    blockList: {
        list: ['foo@emailjs.com', 'bar@emailjs.com'],
        watchVariable: 'userEmail',
    },
    limitRate: {
        id: 'app',
        throttle: 10000,
    },
});

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSendEmail = (e) => {
        e.preventDefault();

        try {

            if (name === '' || email === '' || phone === '' || message === '') {
                toast.error('Por favor, rellene todos los campos');
                return;
            }

            const emailData = {
                to_email: "contacto.centrokaili@gmail.com",
                from_name: name + "\n Correo: " + email + "\n Teléfono: " + phone,
                message: message,
            };

            emailjs.send('service_vjnm2re', 'template_g2gle98', emailData)
            toast.success('Mensaje enviado correctamente, muchas gracias por contactarnos!');
            console.log('Enviado!');
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Fade>
                <Toaster />
                <Container className="mt-5">
                    <h1 className="text-center display-6 fw-bold" style={{ color: '#219ebc' }}>Contacto</h1>

                    <Row className="mt-5 mb-5 d-flex">
                        <Col>
                            <h1 className="display-6 mb-4" style={{ color: '#219ebc' }}>¿Cómo contactarnos?</h1>
                            <p className='fs-4'><FontAwesomeIcon icon={faInstagram} style={{ marginRight: '1%', color: '#DB6E6E' }} />centro.kaili</p>
                            <p className='fs-4'><FontAwesomeIcon icon={faPhone} style={{ marginRight: '1%', color: '#DB6E6E' }} />+569 5769 2636</p>
                            <p className='fs-4'><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '1%', color: '#DB6E6E' }} />contacto.centrokaili@gmail.com</p>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <h1 className="text-center display-6 mb-5 fw-bold" style={{ color: '#219ebc' }}>¡Comunícate con nosotros!</h1>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Control className="form-control-lg" type="text" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control className="form-control-lg" type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Control className="form-control-lg" type="tel" placeholder="Teléfono" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control className="form-control-lg" as="textarea" rows={3} placeholder="Mensaje" value={message} onChange={(e) => setMessage(e.target.value)} />
                            </Form.Group>
                            <Button className="btn btn-primary btn-lg text-center mb-5" variant="primary" onClick={handleSendEmail}>
                                Enviar
                            </Button>
                        </Form>
                    </Row>
                </Container>
            </Fade>
        </>
    )
}

export default Contact;