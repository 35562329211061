import React from 'react';
import { Row, Col } from 'react-bootstrap';
import logo from '../images/logo-kaili.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Fade } from 'react-awesome-reveal';

const Footer = () => {
    return (
        <Fade>
            <footer className="text-black py-4 fs-4" style={{ backgroundColor: '#cae9ff' }}>
                <Row style={{ marginLeft: '12%' }}>
                    <Col md={4} className='text-left'>
                        <h1 className='fw-bold fs-5 mb-3'>Información de contacto</h1>
                        <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                        <p className='fs-6'><FontAwesomeIcon icon={faInstagram} style={{ marginRight: '2%', color: '#DB6E6E' }} />@centro.kaili</p>
                        <p className='fs-6'><FontAwesomeIcon icon={faPhone} style={{ marginRight: '2%', color: '#DB6E6E' }} />+569 5769 2636</p>
                        <p className='fs-6'><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '2%', color: '#DB6E6E' }} />contacto.centrokaili@gmail.com</p>
                    </Col>

                    <Col md={4} className='text-left'>
                        <h1 className='fw-bold fs-5 mb-3'>Horario de atención</h1>
                        <p className='fs-6'>Lunes a viernes: 9:00 a 19:00 hrs</p>
                        <p className='fs-6'>Sábado: 9:00 a 13:30 hrs</p>
                    </Col>

                    <Col md={4} className='text-left'>
                        <img src={logo} className="App-logo" alt="logo" style={{ height: '15vmin' }} />
                    </Col>
                </Row>
            </footer>
        </Fade>
    );
};

export default Footer;